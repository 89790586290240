<template>
  <div class="review-more-mob">
    <mob-header></mob-header>
    <div class="main">
      <yl-channel></yl-channel>
      <div class="title">直播回顾</div>
      <mob-video
        v-for="(item, i) in reviewDataList"
        :key="i"
        :data="item"
        @refDataList="getMoreReviewList()"
      ></mob-video>
    </div>
  </div>
</template>

<script>
import MobHeader from "../../../components/mob-header.vue";
import YlChannel from "../../../components/yl-channel.vue";
import MobVideo from "../../../components/mob-video.vue";
import { moreReviewList } from "../../../api/review";
import wechat from "../../../utils/wxShare";
import { wechatConfig } from "../../../api/user";
export default {
  components: {
    MobHeader,
    YlChannel,
    MobVideo,
  },
  data() {
    return {
      reviewDataList: [],
    };
  },
  created() {
    this.getMoreReviewList();
    this.getWechatConfig();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    getMoreReviewList() {
      moreReviewList().then((res) => {
        if (res.code == 0) {
          this.reviewDataList = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.review-more-mob {
  height: 100%;
  background: url("../../../assets/mobbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    padding: 0 0.1rem 0.9rem;
    .title {
      font-size: 0.16rem;
      font-weight: 500;
      margin: 0.2rem 0 0.1rem;
    }
  }
}
</style>
