<template>
  <div class="review-more-pc">
    <pc-header></pc-header>
    <div class="main">
      <yl-channel></yl-channel>
      <div class="title">直播回顾</div>
      <div class="live-wrap">
        <pc-video
          v-for="(item, i) in reviewDataList"
          :key="i"
          :data="item"
          @refDataList="getMoreReviewList()"
        ></pc-video>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "../../../components/pc-header.vue";
import PcFooter from "../../../components/pc-footer.vue";
import YlChannel from "../../../components/yl-channel.vue";
import PcVideo from "../../../components/pc-video.vue";
import { moreReviewList } from "../../../api/review";
export default {
  components: {
    PcHeader,
    PcFooter,
    YlChannel,
    PcVideo,
  },
  data() {
    return {
      reviewDataList: [],
    };
  },
  created() {
    this.getMoreReviewList();
  },
  methods: {
    getMoreReviewList() {
      moreReviewList().then((res) => {
        if (res.code == 0) {
          this.reviewDataList = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.review-more-pc {
  height: 100%;
  background: url("../../../assets/pcbg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 65px;
    .yl-channel {
      margin: 40px 0;
    }
    .title {
      font-size: 24px;
      color: #fff;
      margin-bottom: 20px;
    }
    .live-wrap {
      display: flex;
      flex-wrap: wrap;
      min-height: 200px;
      .pc-video {
        margin-bottom: 35px;
      }
      .pc-video:nth-child(3n-1) {
        margin: 0 15px 35px;
      }
    }
  }
}
</style>
