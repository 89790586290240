<template>
  <div class="page-view">
    <review-more-mob v-if="isMobile"></review-more-mob>
    <review-more-pc v-else></review-more-pc>
  </div>
</template>

<script>
import ReviewMorePc from "./review-more-pc.vue";
import ReviewMoreMob from "./review-more-mob.vue";
export default {
  components: {
    ReviewMorePc,
    ReviewMoreMob,
  },
  metaInfo: {
    title: "因美纳讲堂 | 回顾",
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
};
</script>
